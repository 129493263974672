<template>
  <div class="contain">
    <div class="modalityTop">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs">
        <el-breadcrumb-item>巡线系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>巡检管理</el-breadcrumb-item>
        <el-breadcrumb-item><span>巡检轨迹</span></el-breadcrumb-item>
      </el-breadcrumb>
      <el-form
        ref="search"
        class="searchForm"
        :model="search"
        label-width="auto"
      >
        <el-form-item label="时间段" prop="equipmentStatus">
          <el-date-picker
            @change="search"
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="巡线员" prop="equipmentStatus" >
          <el-select
            clearable
            style="width:170px"
            v-model="search.userId"
            placeholder="请选择巡线员"
          >
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.username"
              :value="item.id">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="打卡情况" prop="equipmentStatus" >
          <el-select
            clearable
            style="width:170px"
            v-model="search.taskGenerateStatus"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" prop="equipmentStatus">
          <el-input
          clearable
            placeholder="请输入任务编号/任务名称/执行人员姓名"
            v-model="search.searchText"
            style="width:340px"
          >
          </el-input>
        </el-form-item>
       
        <el-button
          size="mini"
          type="primary"
          @click="inquire"
          style="width:80px;height:40px"
          >查询</el-button
        >
        <el-button
          size="mini"
          class="reset"
          style="margin-left: 10px;width:80px;height:40px"
          @click="reset"
          >重置</el-button
        >
      </el-form>
    </div>
    <!-- <div class="top-bar">
      <div class="float-right">
        <span style="margin-top:4px ">时间范围：</span>
        <el-date-picker
            @change="inquire"
            size="mini"
            v-model="DataPicker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
        <el-input clearable placeholder="请输入巡线编码/任务名称/巡检人姓名" v-model="search.searchText" size="mini" style="margin-left: 10px;width:250px;"></el-input>
        <el-button class="reset" icon="el-icon-search" type="primary" @click="inquire" size="mini" style="margin-left: 10px">查询</el-button>
        <el-button size="mini" icon="el-icon-refresh" type="primary" class="reset" @click="reset">重置</el-button>
      </div>
    </div> -->
    <div class="modalityCenter">
     <!-- <el-button
          size="mini"
          type="primary"
          class="addButton"
          @click="goPath('/home/event')"
          >添加</el-button
        > -->
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      max-height="550"
      :cell-style="{textAlign:'center'}"
      :header-cell-style="{
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        textAlign: 'center',
        fontWeight: 900,
        background: '#DCDFE8',
      }"
    >
     <el-table-column prop="taskCode" label="巡线编号">

      </el-table-column>
      <el-table-column prop="taskName" label="任务名称">

      </el-table-column>
      <el-table-column prop="executorPeopleName" label="巡线人"></el-table-column>
      <el-table-column prop="scheduleStartTime" label="开始时间"></el-table-column>
      <el-table-column prop="scheduleEndTime" label="结束时间"></el-table-column>
      <el-table-column prop="scheduleTime" label="巡线总时长（分钟）">
        <template slot-scope="scope">
          {{(scope.row.scheduleTime/60).toFixed(2)}} <span>分钟</span>
        </template>
      </el-table-column>
      <el-table-column prop="scheduleDistance" label="巡线总里程（km）/管线总长度（km）">
        <template slot-scope="scope">
            {{(scope.row.scheduleDistance/1000).toFixed(2)}} / {{(scope.row.lineLength/1000).toFixed(2)}}
        </template>
      </el-table-column>
      <el-table-column  label="事件上报数目">
        <template slot-scope="scope">
            <router-link v-if="scope.row.eventNumber!=0"   :to="{path:'/incident',query: {eventIds:scope.row.eventIds}}"> {{ scope.row.eventNumber }}</router-link>
            <template v-else>0</template>
        </template>

      </el-table-column>
      <el-table-column prop="isPushNumber" label="打卡点">

       <template slot-scope="scope">

         {{scope.row.allPush}}<span>个(已打</span><span style="color:red">{{scope.row.isPushNumber}}</span><span>个)</span>
         
        </template>


      </el-table-column>
      <el-table-column
        fixed="right"
        align="left"
        label="操作">
        <template slot-scope="scope">
          <el-button @click="SeetracFun(scope)" type="text" size="small">查看轨迹</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        style="margin-top: 10px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="search.size"
        :current-page="search.current"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    </div>
    <!-- <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column prop="taskCode" label="巡线编号">

      </el-table-column>
      <el-table-column prop="taskName" label="任务名称">

      </el-table-column>
      <el-table-column prop="executorPeopleName" label="巡线人"></el-table-column>
      <el-table-column prop="scheduleStartTime" label="开始时间"></el-table-column>
      <el-table-column prop="scheduleEndTime" label="结束时间"></el-table-column>
      <el-table-column prop="scheduleTime" label="巡线总时长（分钟）">
        <template slot-scope="scope">
          {{(scope.row.scheduleTime/60).toFixed(2)}} <span>分钟</span>
        </template>
      </el-table-column>
      <el-table-column prop="scheduleDistance" label="巡线总里程（km）/管线总长度（km）">
        <template slot-scope="scope">
            {{(scope.row.scheduleDistance/1000).toFixed(2)}} / {{(scope.row.lineLength/1000).toFixed(2)}}
        </template>
      </el-table-column>
      <el-table-column  label="事件上报数目">
        <template slot-scope="scope">
            <router-link v-if="scope.row.eventNumber!=0"   :to="{path:'/home/event',query: {eventIds:scope.row.eventIds}}"> {{ scope.row.eventNumber }}</router-link>
            <template v-else>0</template>
        </template>

      </el-table-column>
      <el-table-column prop="isPushNumber" label="打卡点">

       <template slot-scope="scope">

         {{scope.row.allPush}}<span>个(已打</span><span style="color:red">{{scope.row.isPushNumber}}</span><span>个)</span>
         
        </template>


      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作">
        <template slot-scope="scope">
           <el-button @click="DetailFun(scope)" type="text" size="small">查看</el-button>
          <el-button @click="SeetracFun(scope)" type="text" size="small">查看轨迹</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="search.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div> -->

    <el-dialog :visible.sync="taskBool">
      <div style="width:100%;height:500px;" id="taskMap">

      </div>
    </el-dialog>
  </div>
</template>

<script>
import { trackList,getTrackPath } from '../../RequestPort/Inspection/inspeTrack'
import { getUserList } from "../../RequestPort/maintenance/task";
import Cookies from 'js-cookie'
export default {
  name: "inspeTrack",
  data() {
    return {
      search: {
        current: 1,
        searchText: "",
        size: 15,
      },
      DataPicker:[],
      tableData: [],
      multipleSelection: [],
      total:0,
      // 弹窗
      taskBool:false,
      // 巡线员下拉
      excutorDownArray:[],
      // 打卡情况
      options:[
        {label:"已完成",value:1},
        {label:"未完成",value:0},
      ],
      Map:{},
      wms:null,
      labelsLayer:null,
      polygonNet:null,
      wdk:require('@/assets/wdk.png'),
      ydk:require('@/assets/ydk.png'),
      xjd:require('@/assets/xjd.png'),
      sj:require('@/assets/sj.png'),
      legend:[
        {
          img: require('@/assets/legend/yh/300018.png'),
          id: '300018',
          name: '压力表'
        },
        {
          img: require('@/assets/legend/yh/300019.png'),
          id: '300019',
          name: '流量计'
        },
        {
          img: require('@/assets/legend/yh/300020.png'),
          id: '300020',
          name: '温度计'
        },
        {
          img: require('@/assets/legend/yh/300021.png'),
          id: '300021',
          name: '泄漏报警器'
        },
        {
          img: require('@/assets/legend/yh/300022.png'),
          id: '300022',
          name: '阀门'
        },
        {
          img: require('@/assets/legend/yh/300023.png'),
          id: '300023',
          name: '摄像头'
        }
      ],
    }
  },
  mounted() {
    if(Cookies.get("name")){
      let strToObj = JSON.parse(Cookies.get("name"))
      this.search = strToObj
    }
    this.getTrackList()
    // 获取巡线员
    getUserList({type:2}).then((e) => {
      this.excutorDownArray = e.data;
    });
  },
  methods: {
    /**
     * 查询
     */
    inquire () {
      this.search.current = 1
      let obj = JSON.stringify(this.search)
      Cookies.set("name", obj)
      this.getTrackList()
    },
    reset(){
      this.search={
        current: 1,
        searchText: "",
        size: 15,
      }
      this.DataPicker = []
      this.getTrackList()
    },
    SeetracFun(e){
      // 五公里
      getTrackPath({id:e.row.id,precise:5000}).then((e)=>{
        this.taskBool = !this.taskBool
        this.$nextTick(()=>{
           this.Map = new AMap.Map("taskMap", {
            center: e.data.trackPath[0],
            zoom: 14
          });
          if(e.data.trackPath&&e.data.trackPath.length>0){
          // e.data.allClock
           
          let icon = new AMap.Icon({
            size: new AMap.Size(58, 70),    // 图标尺寸
            image: 'https://webapi.amap.com/theme/v1.3/markers/b/start.png',  // Icon的图像地址
            imageSize: new AMap.Size(20, 30)   // 根据所设置的大小拉伸或压缩图片
          });

          let marker = new AMap.Marker({
            position: new AMap.LngLat(e.data.trackPath[0][0], e.data.trackPath[0][1]),
            offset: new AMap.Pixel(-10, -10),
            icon: icon, // 添加 Icon 实例
            title: '自定义图标',
            zoom: 13
          });

          let icon1 = new AMap.Icon({
            size: new AMap.Size(58, 70),    // 图标尺寸
            image: 'https://webapi.amap.com/theme/v1.3/markers/b/end.png',  // Icon的图像地址
            imageSize: new AMap.Size(20, 30)   // 根据所设置的大小拉伸或压缩图片
          });

          let marker1 = new AMap.Marker({
            position: new AMap.LngLat(e.data.trackPath[e.data.trackPath.length-1][0], e.data.trackPath[e.data.trackPath.length-1][1]),
            offset: new AMap.Pixel(-10, -10),
            icon: icon1, // 添加 Icon 实例
            title: '自定义图标',
            zoom: 13
          });
          let polyline = new AMap.Polyline({
            path: e.data.trackPath,
            isOutline: true,
            outlineColor: '#ffeeff',
            borderWeight: 3,
            strokeColor: "#3366FF",
            strokeOpacity: 1,
            strokeWeight: 6,
            // 折线样式还支持 'dashed'
            strokeStyle: "solid",
            // strokeStyle是dashed时有效
            strokeDasharray: [10, 5],
            lineJoin: 'round',
            lineCap: 'round',
            zIndex: 50,
          })
          this.Map.add(marker)
          this.Map.add(polyline)
          this.Map.add(marker1)
          }
          this.setWms(e.data.networkId,e.data.allClock,e.data.punch,e.data.networkPath)
        })
      })
    },
    setWms(networkId,data,punch,netpath){

    
   this.allClock(data,punch,netpath)
      if(this.wms){
        this.Map.remove(this.wms)
      }
       this.wms = new AMap.TileLayer.WMS({
                  url:'/geoserver/wms',
                  tileSize: 512,
                  dataZooms: [0, 20],
                  zooms: [0, 20],
                  zIndex: 5,
                  params: {
                    LAYERS: 'gas:patrol_lines',//layer,
                    VERSION: '1.1.1',  
                    TRANSPARENT:true,
                    timestamp: Date.parse(new Date()),
                    CQL_FILTER: `network_id=${networkId} and general_status=1`           
                  }
        })
      this.Map.add(this.wms)

    },
    allClock(data,punch,netpath) {
      let that = this
      if(this.labelsLayer){
        this.Map.remove(this.labelsLayer)
      }
      let markers = []
      that.labelsLayer = new AMap.LabelsLayer({
        zooms: [0, 20],
        zIndex: 130,
        collision: false,
        allowCollision: false
      })
      if(data&&data.length>0){
      data.forEach((item, index) => {
        let lnglat=item.location
        let styleIcon=that.setImg(item.type)
        var labelMarker = new AMap.LabelMarker({
          position: lnglat.split(' '),
          zIndex: 2,
          icon: {
            image: styleIcon.img,
            anchor: 'bottom-center',
            size: styleIcon.size//[32, 39]
          },
          text: {
            extData: { index: item.id },
            content: item.pointName,
            direction: 'right',
            offset: [0, 0,0,0],
            style: {
              fontSize: 12,
              fillColor: '#fff',
              strokeColor: '#000',
              strokeWidth: 2
            }
          }
        })
       
        markers.push(labelMarker)
      })
      
      }
      if(punch&&punch.length>0){
      punch.forEach((item, index) => {
        let lnglat=item.location
        let styleIcon=that.setImgpunch(item.isFlag)
        var labelMarker = new AMap.LabelMarker({
          position: lnglat.split(' '),
          zIndex: 2,
          icon: {
            image: styleIcon.img,
            anchor: 'bottom-center',
            size: styleIcon.size//[32, 39]
          },
          text: {
            extData: { index: item.id },
            content: item.pointName,
            direction: 'right',
            offset: [0, 0,0,0],
            style: {
              fontSize: 12,
              fillColor: '#fff',
              strokeColor: '#000',
              strokeWidth: 2
            }
          }
        })
       
        markers.push(labelMarker)
      })
      
     
      }
      if(markers.length>0){
            that.labelsLayer.add(markers)   
            that.Map.add(that.labelsLayer)
      }
     
      if(netpath&&netpath.length>0){
        if(this.polygonNet){
        this.Map.remove(this.polygonNet)
        }
        this.polygonNet = new AMap.Polygon({
            path: netpath,
            fillColor: '#01D8FF',
            strokeOpacity: 0.4,
            fillOpacity: 0.2,
            strokeColor: '#2b8cbe',
            strokeWeight: 1,
            strokeDasharray: [5, 5]
          })
          that.Map.add(this.polygonNet)
          that.Map.setFitView([this.polygonNet],true,[10, 10, 10, 10])  
      }
    },
    setImgpunch(isFlag){ 
      
           if(isFlag==1){
             let img=this.ydk
             return {img,size:[32, 39]}
           }
           let img=this.wdk
           return {img,size:[32, 39]}
    },
    /**
     * 添加计划//跳转
     */
    goPath(path, id,strat) {
      this.$router.push({ path: path, query: { id: id, inquire:strat} })
    },
    setImg(type){ 
      //  if(type==1){//事件          
      //     let img=this.sj 
      //      return {img,size:[32, 39]}
      //   }

        let img=this.xjd 
        return {img,size:[32, 39]}
        
    },
    getTrackList () {
      if(this.DataPicker&&this.DataPicker.length>0){
        this.search.startDate = this.DataPicker[0]
        this.search.endDate = this.DataPicker[1]
      }else{
        this.search.startDate = null
        this.search.endDate = null
      }
      trackList(this.search).then(res => {
        if (res.code === 200) {
          const { records, total } = res.data;
          this.tableData = records;
          this.total = total;
        }
      })
    },
    handleSizeChange(e) {
      this.search.size = e;
      this.getTrackList();
    },
    handleCurrentChange(e) {
      this.search.current = e;
      this.getTrackList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    indexMethod (index) {
        return (this.search.current - 1) * this.search.size + index + 1
    },
  }
};
</script>

<style lang="less" scoped>
// .top-bar {
//   .float-right {
//     float: right;
//   }
// }
.contain{
  background-color:transparent;
}
// 搜索栏样式
.modalityTop{
    border: 1px solid #ecedf1;
    padding: 20px;
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 12px;
  // 面包屑
  .crumbs{
    padding: 10px 15px 20px;
    span{
      color: #3069E1;
    }
  }
}
// 搜索
.searchForm{
  display: flex;
   flex-wrap: wrap;
    justify-content: space-between;
  .el-form-item{
    margin: 0;
  }
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  padding: 20px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
  }
}
// .el-input-group,
// .el-input {
//   width: initial;
// }
// .block {
//   text-align: center;
//   margin-bottom: 100px;
// }
// .btn {
//   margin-left: 10px;
// }
</style>
